<template>
  <div class="text-left">
    <BaseHeader :title="'Payments'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4">
      <div class=" mt-2 border-0 ">
        <div class=" card p-sm-2 d-flex align-items-center flex-row w-100 justify-content-between">
          <v-radio-group
            style="margin-top: 20px!important;"
            v-if="userType != userTypes.writer"
            class="group-radio my-0"
            @change="filterWriters"
            v-model="type"
            row
            align="center"
          >
            <v-radio label="All" value="all"></v-radio>
            <v-radio label="Eligible" value="active"></v-radio>
            <v-radio label="Ineligible" value="inactive"></v-radio>
          </v-radio-group>
          <h3 v-else >Payments</h3>

          <v-spacer></v-spacer>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter payments"
            name="url"
            type="text"
            hide-details
          />
        </div>
        <div
          class="card-body px-1 pt-1 pb-0"
          v-if="userType != userTypes.writer"
        >
          <v-alert 
          class="rounded-0"
          dense
          outlined
          prominent
          type="success" 
          >
            `Eligible` are writers whose unpaid balance is greater than
            30$
          </v-alert>
          <!-- datatable -->
          <v-data-table
            :mobile-breakpoint="0"
            :headers="pendingPaymentWriters"
            :items="writer_list"
            :search="$store.state.search"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip outlined :color="item.status ? 'primary' : 'error'" small>
                {{ item.status ? "Eligible" : "Ineligible" }}
              </v-chip>
            </template>
            <template v-slot:[`item.names`]="{ item }">
              <router-link
                :to="{ name: 'ViewWriterPending', params: { id: item.id } }"
                >{{ item.names || "--" }}</router-link
              >
            </template>
            <template v-slot:[`item.balance`]="{ item }">
              ${{ item.balance || "0.0" }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :to="{ name: 'ViewWriterPending', params: { id: item.id } }"
                fab
                elevation="0"
                class="white--text"
                x-small
                icon
              >
                <v-icon color="primary">mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <!-- /datatable -->
        </div>

        <div v-else class="card-body px-1 pt-1 pb-0 py-4">
          <div class="m-2">
            To view your pending payments and paid, click
            <router-link
              :to="{ name: 'ViewWriterPending', params: { id: user.id } }"
              >here.</router-link
            >
            <br />
            <router-link
              :to="{ name: 'ViewWriterPending', params: { id: user.id } }"
              class="btn btn-primary text-light mt-2"
              >View my payments</router-link
            >
          </div>
        </div>
      </div>

      <div
        class="card border-0 bg-white shadow"
        v-if="selected.length > 0 && userType == userTypes.admin"
      >
        <div class="card-body">
          <h5 class="text-uppercase">With Selected</h5>
          <button @click="markAsPaid" class="btn btn-primary">
            Mark as Paid
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
// import http from "../../modules/http.index";
export default {
  name: "Payments",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("payment", [
      "pendingTotals",
      "pendingPayments",
      "pendingPaymentWriters",
      "pendingPaymentsHeaders",
    ]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("writers", ["writers", "loading"]),
  },

  data() {
    return {
      writer_id: "",
      selected: [],
      writer_list: [],
      type: "all",
      loading: true
    };
  },

  methods: {
    ...mapActions("writers", ["getActiveWriters"]),
    filterWriters() {
      if (this.type === "active") {
        this.writer_list = this.writers.filter((item) => {
          return Boolean(item.status) === true || item.status === "1";
        });
      } else if (this.type === "inactive") {
        this.writer_list = this.writers.filter(
          (item) => Boolean(item.status) === false
        );
      } else {
        this.writer_list = this.writers;
      }
    },
  },

  async mounted() {
    if (this.userType != this.userTypes.admin) {
      console.log("user", this.user);
    }
    this.loading = true
    try {
      await this.getActiveWriters();
      this.writer_list = this.writers;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  },
};
</script>
